//import np-dict from public/np-dict.json

import { Autocomplete, Container, TextField, Typography } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import NpDict from './np-dict.json';

// add "|"" to sparate letters to the dictionary

// split the dictionary into unique letters

const uniqueLetters = NpDict.uniqueLetters;

// create World Blocks object with empty arrays keys for each letter in uniqueLetters
let PlBlocks = uniqueLetters.reduce((acc: Record<string, any>, letter) => {
  acc[letter] = [];
  return acc;
}, {});

console.log(PlBlocks);

// do a for loop with index over the dictionary
for (const [index, word] of NpDict.PL.entries()) {
  // get the first letter of the word
  const firstLetter = word[0].toLocaleLowerCase();
  // push the word to the array of the first letter

  const dictPair = {
    word: word,
    translation: NpDict.UKR[index],
  };

  PlBlocks[firstLetter].push(dictPair);
}

//set box-shadow only for bottom left right border and only when is sticcied
const LettersHeader = styled.div`
  position: sticky;
  top: 80px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 10px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
`;

const UniqueLetters = styled.h2`
  cursor: pointer;
  margin: 0 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
`;

export default function Dictionary() {
  const [value, setValue] = React.useState<string | null>(null);

  useEffect(() => {
    // scroll to the id of the value
    const element = document.getElementById(value!);
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, [value]);

  return (
    <Fragment>
      <LettersHeader>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={NpDict.PL}
          value={value}
          sx={{ width: '200px' }}
          onChange={(event: any, newValue: string | null) => {
            setValue(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} size="small" label="Wpisz słowo" />
          )}
        />
        {NpDict.uniqueLetters.map((letter) => (
          <UniqueLetters
            key={letter}
            onClick={() => {
              const element = document.getElementById(letter);
              element?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
              });
            }}
          >
            {letter}
          </UniqueLetters>
        ))}
      </LettersHeader>
      <Container maxWidth="md">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          {Object.keys(PlBlocks).map((letter) => (
            <dl key={letter} id={letter} style={{ scrollMarginTop: '140px' }}>
              <dt>
                <Typography variant="h4">
                  {letter.toLocaleUpperCase()}
                </Typography>
              </dt>
              {PlBlocks[letter].map(
                (wordPair: { word: string; translation: string }) => (
                  <dd
                    key={wordPair.word}
                    id={wordPair.word}
                    style={{ scrollMarginTop: '140px' }}
                  >
                    <strong>{wordPair.word}</strong> - {wordPair.translation}
                  </dd>
                )
              )}
            </dl>
          ))}
        </div>
      </Container>
    </Fragment>
  );
}
