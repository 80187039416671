import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Dictionaty from './Dictionary';
import styled from 'styled-components';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function Copyright() {
  return (
    <Typography my={4} variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Edu & More
      </Link>{' '}
      {new Date().getFullYear()} by Tymon Żarski.
    </Typography>
  );
}

//create fixed navbar styled-component

const NavbarStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 10px 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-width: 940px;
`;

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// create fived navbar with logo "logo_edu.gif" and pagr name "słownik pojęć"
const Navbar = () => {
  return (
    <NavbarStyles>
      <Container maxWidth="lg">
        <NavbarContent>
          <img
            src="logo_edu.gif"
            alt="logo"
            height={50}
            onClick={() => {
              // smooth scroll to top
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
          />
          <Typography variant="h5">Słownik pojęć</Typography>
        </NavbarContent>
      </Container>
    </NavbarStyles>
  );
};

// sickty button back to top
const BackToTop = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  padding: 10px 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #813782;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
`;

export default function App() {
  return (
    <React.Fragment>
      <Navbar />
      <Container maxWidth="lg" sx={{ minWidth: '940px' }}>
        <Box sx={{ marginTop: '96px' }}>
          <Dictionaty />
          <Copyright />
        </Box>
      </Container>
      <BackToTop
        onClick={() => {
          // smooth scroll to top
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <ArrowUpwardIcon fontSize="medium" sx={{ color: 'white' }} />
      </BackToTop>
    </React.Fragment>
  );
}
